var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-chien" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("fil-ariane", { attrs: { navigation: _vm.navigation } }),
        _vm.getErrors
          ? _c(
              "ul",
              { staticClass: "error-messages" },
              _vm._l(_vm.getErrors, function (v, k) {
                return _c("li", { key: k }, [
                  _vm._v(_vm._s(_vm._f("error")(v))),
                ])
              }),
              0
            )
          : _vm._e(),
        _c("h1", [
          _vm._v(" " + _vm._s(_vm.getPrevDog()) + " "),
          _vm.getPrevDog(_vm.id)
            ? _c(
                "button",
                {
                  staticClass: "goto-previous",
                  on: {
                    click: function ($event) {
                      return _vm.onGotoPrevDog(_vm.id)
                    },
                  },
                },
                [_c("PreviousLogo", { staticClass: "previous" })],
                1
              )
            : _vm._e(),
          _vm._v(" " + _vm._s(this.getRace) + " "),
          _vm.getNextDog(_vm.id)
            ? _c(
                "button",
                {
                  staticClass: "goto-next",
                  on: {
                    click: function ($event) {
                      return _vm.onGotoNextDog(_vm.id)
                    },
                  },
                },
                [_c("NextLogo", { staticClass: "next" })],
                1
              )
            : _vm._e(),
          _c("br"),
          _c("span", { class: `clazz ${_vm.getClassSex}` }, [
            _vm._v(
              _vm._s(this.getClassLabel) +
                " " +
                _vm._s(
                  this.dog.nr_sexe === "1"
                    ? _vm.$t("global.male")
                    : _vm.$t("global.female")
                ) +
                " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "flex-header" }, [
          _c("div", { staticClass: "info" }, [
            _c("h2", [
              _vm._v(
                " N° " +
                  _vm._s(this.dog.nr_cat) +
                  " - " +
                  _vm._s(this.dog.identifiant) +
                  " "
              ),
            ]),
            this.dog.nr_sexe === "1"
              ? _c("h3", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("dog.his_birthday", {
                          date: _vm.getFormatedBirthday(),
                        })
                      ) +
                      " - " +
                      _vm._s(
                        _vm.$t("dog.age", {
                          nbYears: Math.floor(_vm.getAgeByMonth() / 12),
                          nbMonths: Math.floor(_vm.getAgeByMonth() % 12),
                        })
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "shortcuts" }, [
            _c("div", { staticClass: "actions" }, [
              _c(
                "button",
                {
                  staticClass: "action cancel",
                  attrs: { disabled: _vm.getRewards.length > 0 },
                  on: { click: _vm.onReset },
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.reset")))]
              ),
              _c(
                "button",
                {
                  staticClass: "action submit",
                  attrs: { disabled: !_vm.canSubmit },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.save")))]
              ),
            ]),
          ]),
        ]),
        _vm.isLocked
          ? _c("div", { staticClass: "message information" }, [
              _vm._v(" " + _vm._s(_vm.$t("dog.no_changes_allowed")) + " "),
            ])
          : _vm._e(),
        _vm.getRewards
          ? _c(
              "ul",
              { staticClass: "rewards" },
              _vm._l(_vm.getRewards, function (reward) {
                return _c(
                  "li",
                  { key: reward, staticClass: "reward", class: reward },
                  [
                    !["RCACS", "CACS", "RCACIB", "CACIB"].includes(reward)
                      ? _c("RewardLogo", [
                          _c("title", {
                            domProps: { innerHTML: _vm._s(reward) },
                          }),
                        ])
                      : _vm._e(),
                    _c("span", { domProps: { innerHTML: _vm._s(reward) } }),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        this.dog.nr_sexe === "2"
          ? _c("h3", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("dog.her_birthday", {
                      date: _vm.getFormatedBirthday(),
                    })
                  ) +
                  " - " +
                  _vm._s(
                    _vm.$t("dog.age", {
                      nbYears: Math.floor(_vm.getAgeByMonth() / 12),
                      nbMonths: Math.floor(_vm.getAgeByMonth() % 12),
                    })
                  ) +
                  " "
              ),
              this.dog.a_confirmer === "1"
                ? _c("span", { staticClass: "to_be_confirmed" }, [
                    _vm._v(" " + _vm._s(_vm.$t("dog.to_be_confirmed")) + " "),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.comment,
              expression: "comment",
            },
          ],
          attrs: { placeholder: _vm.$t("dog.comment"), disabled: _vm.isLocked },
          domProps: { value: _vm.comment },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.comment = $event.target.value
            },
          },
        }),
        _c("grille-qualification", {
          attrs: {
            classe_id: _vm.dog.classe_id,
            qualifier_id: "" + _vm.qualifier,
            disabled: this.isLocked,
          },
          on: { "change-qualifier": _vm.onChangeQualifier },
        }),
        _c("div", { staticClass: "actions" }, [
          _c(
            "button",
            { staticClass: "action back", on: { click: _vm.onGoBack } },
            [_vm._v(_vm._s(_vm.$t("global.back")))]
          ),
          _c(
            "button",
            {
              staticClass: "action cancel",
              attrs: { disabled: this.isLocked },
              on: { click: _vm.onReset },
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.reset")))]
          ),
          _c(
            "button",
            {
              staticClass: "action submit",
              attrs: { disabled: !_vm.canSubmit },
              on: { click: _vm.onSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("global.save")))]
          ),
          _c("div", { staticClass: "break" }),
          _c(
            "button",
            {
              staticClass: "action submit-leave",
              attrs: { disabled: !_vm.canSubmit },
              on: { click: _vm.onSubmitAndLeave },
            },
            [_vm._v(_vm._s(_vm.$t("global.submit_and_leave")))]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }